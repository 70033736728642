import { ISavedCVOCHierarchy } from "../interfaces/ICVOCHierarchy";
import { IFilterTracker } from "../interfaces/IFilterTracker";

export const savedHierarchy: ISavedCVOCHierarchy[] = [
    { level: 1, hierarchyID: null, hierarchyName: null },
    { level: 2, hierarchyID: null, hierarchyName: null },
    { level: 3, hierarchyID: null, hierarchyName: null },
    { level: 4, hierarchyID: null, hierarchyName: null },
    { level: 5, hierarchyID: null, hierarchyName: null },
]

export const DEFAULT_EVOC_SELECTION = [
    { hierarchyName: 'region', level: 1, selectedValue: null },
    { hierarchyName: 'market', level: 2, selectedValue: null },
    { hierarchyName: 'allocationMarket', level: 3, selectedValue: null },
    { hierarchyName: 'country', level: 4, selectedValue: null },
]

export const CVOC_FILTERTYPE_NAME = 'CVOC'
export const EVOC_FILTERTYPE_NAME = 'EVOC'
export const FILTER_ICON = '../../../../../assets/images/filter.svg'
export const EVOC_FILTER_NAMES = ['Region', 'Market', 'Allocation Market', 'Country']
export const ECOLAB = 'Ecolab'
export const ALERTICON='assets/images/AlertIcons.svg'
export const PRIVACY_POLICY = 'Privacy Policy';
export const TERMS_CONDITIONS = 'Terms of Use';
export const LOGOUT = 'Log Out';
export const ALL_FILTERS_TEXT = { FILTER_RESULTS: 'Filter Results', NUMBER_OF_FILTERS: 'filters selected', VIEW_BY: 'View By:', CUSTOMER_VIEW: 'Customer View', ECOLAB_VIEW: 'Ecolab View', SITES: 'Sites' };
export const FILTER_TYPES = { CVOC: 'CVOC', EVOC: 'EVOC', SITES: 'site', OPTYPE: 'Operating Type', CUSTOMER_DIVISION: 'Division' };
export const ELASTIC_APM_SERVICENAME = 'one-customer-TVD'
export const DEFAULT_OPERATING_FILTER_TYPE = ''
export const CHECKBOX_THEME = 'primary'
export const ERROR_TITLE='Your connection is taking too long to load'
export const TIMEOUT_CONTENT='There is an issue that’s causing the page you requested to take too long to load.<br>You can try reloading the page or go back to the login screen.'
export const FILTER_VALIDATION: IFilterTracker[] = [{ filterName: 'CVOC', error: false }, { filterName: 'EVOC', error: false }, { filterName: 'site', error: false }, { filterName: 'opType', error: false }, { filterName: 'division', error: false }]
export const SNACKBAR_MESSAGE='Error: There is no data available for your selection. Please make another selection.'
export const TVD_DATE_FORMAT = {
    parse: {
        dateInput: 'MMM-YYYY',
    },
    display: {
        dateInput: 'MMM-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const END_SESSION_PATH = 'endsession';
