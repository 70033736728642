import { Component } from '@angular/core';
import { AuthenticationService } from '../../../../core/services/authentication.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrl: './customer.component.scss',
})
export class CustomerComponent {
  loginDisplay: boolean = false;


  constructor(
    private ssoService: AuthenticationService) { }
  ngOnInit() {
    this.ssoService?.userSSO().subscribe(
      () => {
      }
    );
    this.ssoService.checkProgress().subscribe(
      () => {
        this.setLoginDisplay();
      }
    );
  }

  setLoginDisplay(): void {
    this.loginDisplay = this.ssoService?.getAllAccountsLength();
  }
}




