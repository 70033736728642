<div class="valueMetricDialog">
  <h1 mat-dialog-title>{{'TVD.ADD_VALUE_ACTIVITY_METRIC' | translate}}</h1>
  <button class="valueMetricDialog-closeButton" (click)="closeModel()"><img [src]="CLOSE_ICON" alt="close"></button>
</div>
<mat-dialog-content class="mat-dialog-content">
  <form [formGroup]="metricsForm">
    <div formArrayName="metrics">
      <div *ngFor="let metric of metrics.controls; let i = index" [formGroupName]="i" class="metrics-border-h">
        <div class="metric-header">
          <h1>{{'TVD.VALUE_ACTIVITY_METRIC' | translate}} {{ i + 1 }}</h1>
          <button class="" (click)="deleteMetric(i,metric.value.id)">
            <img [src]="DELETE_ICON" alt="">
          </button>
        </div>
        <div class="w-100">
          <p class="metrics-text">{{'TVD.TYPE_OF_METRIC' | translate}}</p>
          <mat-form-field appearance="outline">
            <mat-select formControlName="categoryId" (valueChange)="onSelectedChange($event,i)">
              <mat-option *ngFor="let catogory of typeCatogory" [value]='catogory.categoryId'>
                {{catogory.vamcategory}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="valueMetricDialog">
          <div class="w-100">
            <p class="metrics-text">{{'TVD.NAME_OF_METRIC' | translate}}</p>
            <mat-form-field appearance="outline">
              <input matInput formControlName="metricName" required [readonly]="inputDisabled[i]"
                (focus)="enableManualInput(i)" maxlength="64">
              <button mat-icon-button matSuffix aria-label="Clear" *ngIf="!inputDisabled[i]"
                (click)="clearField(i, 'metricName')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="w-100">
            <p class="metrics-text">{{'TVD.VALUE' | translate}}</p>
            <mat-form-field appearance="outline">
              <input matInput formControlName="metricValue" type="text" (input)="handleInput($event , i)"
                maxlength=11>
              <button mat-icon-button matSuffix aria-label="Clear" (click)="clearField(i, 'metricValue')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="metrics.length === 0" class="valueMetricDialog-metric-border">
    <button class="valueMetricDialog-add" (click)="addMetric()">
      <img [src]='PLUSIMG'>
      {{'TVD.VALUE_ACTIVITY_METRICS' | translate}}
    </button>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="mat-dialog-actions">
  <button mat-raised-button [ngClass]="this.metrics.length === 8 ? 'button-disabled' : 'add-metric-button'"
    [disabled]="this.metrics.length === 8" (click)="addMetric()">{{'TVD.ADD_METRIC' | translate}}</button>
  <button mat-raised-button color="primary" (click)="applyMetrics()" [disabled]="!metricsForm.valid || isApply">
    {{'TVD.APPLY' | translate}}</button>
</mat-dialog-actions>
