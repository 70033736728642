import { Injectable } from '@angular/core';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Observable, catchError, filter, throwError } from 'rxjs';
import { IAuthorizationApi } from '../../interfaces/IAuthorizationApi';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  activeAccount: any;
  accounts: any;
  headers = new HttpHeaders({
    'Content-type': 'application/json'
  })
  constructor(
    private http: HttpClient,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) { }
  /**
   @author charan
   @param url dynamic url passed
   @return getApi return get responce
  */

  getApi(url: string) {
    return this.http.get(url, { headers: this.headers });
  }


  logoutTVD() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.ECOLAB_WEB,
    });
    this.authService.logout();
  }
  /**
   *Method used to get user Application access from the data source.
   * @returns Api response as Observable
   */
  getCurrentUserAppAccess(): Observable<IAuthorizationApi> {
    let authorizationResult =
      this.http.get<IAuthorizationApi>(environment.CDM_AUTHORIZATION_URL)
        .pipe(catchError(this.errorHandler));
    return authorizationResult;

  }
  errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error.message || "Server Error");
  }

  /**
    * Method used to update the user login status.
    * @param emailId Current user email Id
    * @param loginStatus status of login
    * @returns Observable User login status as response
    */
  updateUserLoginStatus(emailId: string, loginStatus: boolean): Observable<any> {
    let loginStatusPayload = {
      emailId: emailId,
      isLoggedOut: loginStatus,
    };
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const url = `${environment.API_DOTNET_TVD_Logout_URL}`;
    const body = JSON.stringify(loginStatusPayload);
    return this.http.post(url, body, { headers: headers });
  }

  logoutTVDAPP(loggedOutStatus: any) {
    this.updateUserLoginStatus(
      this.authService.instance.getAllAccounts()[0].localAccountId,
      loggedOutStatus).subscribe(() => {
        this.authService.logoutRedirect({
          postLogoutRedirectUri: environment.ECOLAB_WEB,
        });
        this.authService.logout();
      });
  }

  checkLoggedOutService() {
    const url = `${environment.API_DOTNET_TVD_Logout_URL}`;
    return this.http.get<any>(url, {
      params: {
        EmailId: this.authService.instance.getAllAccounts()[0].localAccountId
      }
    })
  }


  // test
  userSSO(): Observable<any> {
    return this.msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
    );
    this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    );
  }

  checkProgress(): Observable<any> {
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    );
  }

  getAllAccountsLength(): any {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  getLoggedInUserName(): any {
    return this.authService.instance.getAllAccounts()[0].name;
  }

  getAllClaims(): Observable<any> {
    return this.msalBroadcastService.inProgress$.pipe(
      filter(
        (status: InteractionStatus) =>
          status === InteractionStatus.None ||
          status === InteractionStatus.HandleRedirect
      )
    );
  }

  checkAndSetActiveAccount(): void {
    this.activeAccount = this.authService.instance.getActiveAccount();
    if (
      !this.activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      this.accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(this.accounts[0]);
    }
  }

  getClaimsWithIdTokenClaims() {
    return this.authService.instance.getActiveAccount()?.idTokenClaims;
  }

  getUserMailID() {
    return this.authService.instance.getAllAccounts()[0].localAccountId;
  }

  logoutDI(loggedOutStatus: any) {
    let payload = {
      emailId: this.authService.instance.getAllAccounts()[0].localAccountId,
      isLoggedOut: loggedOutStatus,
    };
    const headers = new HttpHeaders().set('content-type', 'application/json');
    // const url = `https://onecustomer-api-dev.azurewebsites.net/globalpreferences/user/logout`;
    const url = `${environment.API_DOTNET_TVD_Logout_URL}`
    const body = JSON.stringify(payload);
    this.http.post(url, body, { headers: headers }).subscribe(() => {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: environment.ECOLAB_WEB,
      });
      this.authService.logout();
    });
  }
}
