import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { MsalService } from '@azure/msal-angular';
import { ALERTICON, ERROR_TITLE, TIMEOUT_CONTENT } from '../../constant/tvd-constant';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrl: './error-dialog.component.scss'
})
export class ErrorDialogComponent {

  alertImg=ALERTICON;
  timeout_content=TIMEOUT_CONTENT;

  timeout_header=ERROR_TITLE;
  constructor(private authService: AuthenticationService,private msal: MsalService){}

  reloadPage() {
    window.location.reload();
 }

 //this will make the application Logout!
 logOut()
 {
  this.authService.logoutTVDAPP(true);
  this.msal.logout();

 }
}
