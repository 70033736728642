<div class="main-card" [id]="getTileId()">

  <div class="card" [style.background-color]="bgColor" [ngClass]="expandTile? 'cardExpansion' : ''">
    <ng-content></ng-content>
    <div class="card-view-more">
      <div
        [ngClass]="isWaterEnergyWaste() ? 'tiles-text-white' : 'tiles-text-blue'"
        class="card-view-more-text" (click)="viewMoreClicked()">{{viewLabelText}}</div>
      <div class="view-more-drop-img" (click)="viewMoreClicked()">
        <img
          *ngIf="isWaterEnergyWaste();else costBenefits"
          alt="dropdown" src="{{'assets/images/'+viewIcon_white}}" />

        <ng-template #costBenefits>
          <img alt="dropdown" src="{{'assets/images/'+viewIcon_blue}}" />
        </ng-template>
      </div>
    </div>
    <div *ngIf="expandTile">
      <app-tiles-data (toggleOverlay)="onToggleOverlay()" [viewMoreData]="expandTile"
        [customerTilesData]="customerTilesData"></app-tiles-data>
    </div>
    <div *ngIf="expandTile && customer_CnB_TilesData ">
      <app-tiles-data (toggleOverlay)="onToggleOverlay()" [viewMoreData]="expandTile"
        [customerTilesData]="customer_CnB_TilesData"></app-tiles-data>
    </div>
    <div *ngIf="isOverlayVisible" class="overlay">
      <div class="background" [style.background-color]="bgColor"
        [ngClass]="isWaterEnergyWaste()  ? 'tiles-text-white' : 'tiles-text-black'">
        <div class="background-close" [ngClass]="isWaterEnergyWaste() ? 'tiles-text-white' : 'tiles-text-black'">
          <p class="defination">{{'TVD.DEFINITION' | translate }}</p>
          <img [src]="isWaterEnergyWaste() ? icon_close_white : icon_close_black" class="close" (click)="onOverlayClose()"/>
        </div>
        <div *ngIf="helpText" class="background-innerText">
          <p class="main-sentence">{{ helpText[0]  | translate  }}</p>
          <ul>
            <li *ngFor="let item of helpText.slice(1)" class="sub-sentence">{{ item | translate }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>