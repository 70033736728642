<div class="user-preference-filter">
    <mat-card class="user-preference-filter-card" *ngIf="userPreferenceClicked">
      <mat-card-content class="user-preference-filter-content">
        <div *ngFor="let preference of userPrerence" class="align-data">
          <div (click)="getPreference(preference)">
            <img [src]="preference?.img" [id]="preference?.img" />
            <label class="label-data" [id]="preference?.label">{{preference?.label}}</label>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  