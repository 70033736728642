<div class="valueMetric-main">
  <div class="valueMetric-main-section">
  <div class="valueMetric-main-section-heading">
    <h3>{{VALUE_ACTIVITY_METRICS}}</h3>
    <button class="valueMetric-main-section-modify" (click)="valueActiveMetric(DIALOG_MODE_MODIFY)">{{MODIFY}} <img
        [src]='MODIFYIMG'></button>
  </div>
  <div
    [ngClass]="isNew === 0 || isNew === null  ? 'valueMetric-main-section-wrapper--blur':'valueMetric-main-section-wrapper'">
    <div
      [ngClass]="isNew === 0 ||isNew === null ? 'valueMetric-main-section-container':'valueMetric-main-section-metricBoxHeight'">
      <div *ngIf="isNew === 0 || isNew === null" class="valueMetric-main-section-metric-border">
        <button class="valueMetric-main-section-add" (click)="valueActiveMetric(DIALOG_MODE_ADD)">
          <img [src]='PLUSIMG'>
          {{VALUE_ACTIVITY_METRICS}}
        </button>
      </div>
      <div>
        <div *ngIf="isNew" class="valueMetric-main-section-metricBox">
          <div *ngFor="let metricValueTileData of boxdata" class="valueMetric-main-section-metricBox-metricTiles">
            <h1>{{metricValueTileData.metricValue}}</h1>
            <p [title]="metricValueTileData.metricName">{{metricValueTileData.metricName}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
