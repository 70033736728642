import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TvdSetupRoutingModule } from './tvd-setup-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { CustomerComponent } from './components/customer/customer.component';
import { CoreModule } from '../../core/core.module';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OptionalFiltersComponent } from './components/optional-filters/optional-filters.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslationService } from './services/translation.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { CvocFilterComponent } from './components/cvoc-filter/cvoc-filter.component';
import { EVOCFilterComponent } from './components/evoc-filter/evoc-filter.component';
import { SiteFilterComponent } from './components/site-filter/site-filter.component';
import { MatChipsModule} from '@angular/material/chips';
import { MatIconModule} from '@angular/material/icon';
import { OperatingTypeFilterComponent } from './components/operating-type-filter/operating-type-filter.component';
import { ValueMetricDailogComponent } from '../../shared/value-metric-dailog/value-metric-dailog.component';
import { ValueActivityMetricComponent } from './components/value-activity-metric/value-activity-metric.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { DivisionFilterComponent } from './components/division-filter/division-filter.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import {MatSnackBarAction, MatSnackBarActions, MatSnackBarLabel, MatSnackBarModule} from '@angular/material/snack-bar';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
// import {CookieService} from 'ngx-cookie-service';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { ScrollingModule } from '@angular/cdk/scrolling';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    CustomerComponent,
    OptionalFiltersComponent,
    CvocFilterComponent,
    EVOCFilterComponent,
    SiteFilterComponent,
    OperatingTypeFilterComponent,
    ValueMetricDailogComponent,
    ValueActivityMetricComponent,
    DivisionFilterComponent,
    LandingPageComponent,
    SnackBarComponent,
    ErrorDialogComponent
  ],
  imports: [
    CommonModule,ScrollingModule,MatSnackBarModule, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction,MatDividerModule,MatCheckboxModule,NgxUiLoaderModule,MatProgressSpinnerModule, MatDialogModule,MatSelectModule,MatIconModule,ReactiveFormsModule,MatChipsModule,MatInputModule,MatIconButton,MatButtonModule,MatDialogModule,MatFormField,MatLabel,MatButtonToggleModule,MatCardModule,MatAutocompleteModule,TvdSetupRoutingModule,SharedModule, CoreModule,MatDatepicker,MatDatepickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [TranslationService, 
    // CookieService
  ],
  exports:[TranslateModule]
})
export class TvdSetupModule { }
