import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  defaultLang: any;

  
  

  constructor(public translate: TranslateService, private customerService: CustomerService) {}

test: any;
  email = this.customerService.getUserMailID();
  

  selectedLanguage() {
    this.customerService.getPreferences(this.email).subscribe(lang => {
      this.test = lang.data.userPreferences.langKey;
      this.defaultLang = this.translate.getBrowserLang();
      this.defaultLang = this.translate.use(this.test);
    })
  }
}
