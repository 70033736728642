import { NgxUiLoaderConfig, PB_DIRECTION, SPINNER } from "ngx-ui-loader";
// ADD ALL APPLICATION CONSTANTS HERE
export const TVD = 'TVD';
export const VIEW_MORE = 'View More';
export const VIEW_LESS = 'View Less';
export const WATER = 'Water';
export const ENERGY = 'Energy';
export const WASTE = 'Waste';
export const COST_AND_BENEFITS = 'Cost & Benefit';
export const FOOD_SAFETY = 'Food Safety';
export const HUMAN_SAFETY = 'Human Safety';
export const WATER_SAFETY = 'Water Safety';
export const DELIGHTED_GUESTS = 'Delighted Guests';
export const BRAND = 'Brand';
export const QUALITY = 'Quality';
export const REGULATORY_COMPLIANCE = 'Regulatory Compliance';
export const INDUSTRY_COMPLIANCE = 'Industry Compliance';
export const ASSET_PROTECTION = 'Asset Protection';
export const RELIABILITY = 'Reliability';
export const CAPACITY = 'Capacity';
export const LABOR_PRODUCTIVITY = 'Labor Productivity';
export const LOCATION_PRODUCTIVITY = 'Location Productivity';
export const PRODUCT_OPTIMIZATION = 'Product Optimization';
export const RAW_MATERIAL_OPTIMIZATION = 'Raw Material Optimization';
export const VIEW_LESS_ICON_BLUE = 'arrow_drop_down.png';
export const VIEW_LESS_ICON_WHITE = 'arrow-up-white.png';
export const VIEW_MORE_ICON_BLUE = 'drop-down-blue.png';
export const VIEW_MORE_ICON_WHITE = 'arrow-down-white.png';
export const VALUE_ACTIVITY_METRICS = 'Value Activity Metrics';
export const MODIFY = 'Modify';
export const VALUE_ACTIVITY_METRIC = 'Value Activity Metric';
export const ADD_VALUE_ACTIVITY_METRIC = 'Add Value Activity Metric'
export const SITEFILTER_SEARCH_ICON = 'assets/images/FiltersearchIcon.svg'
export const SITE_NOT_FOUND_MSG= 'No Results found for Site'
export const CATEOGRYID_MANUAL = 15;
export const DIALOG_MODE_ADD: string = 'add';
export const DIALOG_MODE_MODIFY: string = 'modify';
export const APPLYVALUECHANEGE: string = 'applyClickChanges';
export const KAY_QSR: string = "KAY-QSR";
export const SITE_IDENTIFIER='site';
export const QSR: string = "QSR";
export const HOW_WAS_THIS_CALCULATED_BLUE = 'assets/images/question-icon-blue.svg';
export const HOW_WAS_THIS_CALCULATED_WHITE = 'assets/images/question-icon-white.svg';
export const HOW_WAS_THIS_CAL = "How was this calculated?";
export const DEFINITION = "Definition";
export const ICON_CLOSE_BLACK = 'assets/images/Icon-close_black.svg';
export const ICON_CLOSE_WHITE = 'assets/images/Icon-close_white.svg';
export const unauthorized_icon = 'assets/images/unauthorized-401-background.svg';

export const LANDINGPAGE = {
  ENTERPRISESITEPIPE: 'enterpriseSitePipleine',
  TVD: 'tvd',
  TVDBORDER: '#006BD3',
  ENTERPRISESITEPIPEBORDER: '#4fc2b0',
  DATEFORMAT: 'YYYY-MM-DD',
}

export const TRANSFORMATION_TYPE = {
  withCurrency: 'currency',
  withoutCurrency: 'number'
}

export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  // fgsColor: '#006bd3',
  fgsSize: 90,
  bgsType: SPINNER.ballSpin, // background spinner type
  fgsType: SPINNER.ballSpinClockwise, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 8, // progress bar thickness
};

export const TILE_IMAGE_MAPPING = [
  { tileName: WATER, imagePath: '../../../../../assets/images/drop.svg' },
  { tileName: ENERGY, imagePath: '../../../../../assets/images/fire.svg' },
  { tileName: WASTE, imagePath: '../../../../../assets/images/recycle.svg' },
  { tileName: COST_AND_BENEFITS, imagePath: '../../../../../assets/images/cost-&-benefits.svg' },
  { tileName: FOOD_SAFETY, imagePath: '../../../../../assets/images/food_safty.svg' },
  { tileName: HUMAN_SAFETY, imagePath: '../../../../../assets/images/human_safety.svg' },
  { tileName: WATER_SAFETY, imagePath: '../../../../../assets/images/water_safety.svg' },
  { tileName: DELIGHTED_GUESTS, imagePath: '../../../../../assets/images/delighted_guests.svg' },
  { tileName: BRAND, imagePath: '../../../../../assets/images/brand.svg' },
  { tileName: QUALITY, imagePath: '../../../../../assets/images/quality.svg' },
  { tileName: REGULATORY_COMPLIANCE, imagePath: '../../../../../assets/images/regular_compliance.svg' },
  { tileName: INDUSTRY_COMPLIANCE, imagePath: '../../../../../assets/images/industry_compliance.svg' },
  { tileName: ASSET_PROTECTION, imagePath: '../../../../../assets/images/asset_protection.svg' },
  { tileName: RELIABILITY, imagePath: '../../../../../assets/images/reliability.svg' },
  { tileName: CAPACITY, imagePath: '../../../../../assets/images/capacity.svg' },
  { tileName: LABOR_PRODUCTIVITY, imagePath: '../../../../../assets/images/labor_productivity.svg' },
  { tileName: LOCATION_PRODUCTIVITY, imagePath: '../../../../../assets/images/location_productivity.svg' },
  { tileName: PRODUCT_OPTIMIZATION, imagePath: '../../../../../assets/images/product_optimization.svg' },
  { tileName: RAW_MATERIAL_OPTIMIZATION, imagePath: '../../../../../assets/images/raw_material.svg' }
]

export const MODIFYIMG = '../../../../../assets/images/modify.svg';
export const PLUSIMG = '../../../../../assets/images/plus.svg';
export const DELETE_ICON = '../../../../../assets/images/delete_icon.svg'
export const CLOSE = '../../../../../assets/images/close.svg'
export const ECLOLAB = '../../../../../assets/images/ecolab.svg'
export const USER = '../../../../../assets/images/user.svg'
