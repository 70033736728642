<div *ngFor="let item of cvocfilterIterations;let i=index">
    <mat-form-field appearance="outline">
        <input type="text" placeholder="{{'Level '+(i+1) }}" aria-label="Number" matInput
            [formControl]="cvocInputControls[i]" [matAutocomplete]="auto" (click)="getCurrentLevelCVOCOptions(i+1)"
            (keyup)="validateCvocFilter(i+1)" [errorStateMatcher]="matcher" id="inputTextColor">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFnCVOC"
            (optionSelected)="getCurrentLevelCVOCOptions(i+1,true)">
            @for (option of filteredOptions_cvoc | async; track option)
            {
            <mat-option [value]="option">{{option.hierarchyName}}</mat-option>
            }
        </mat-autocomplete>
        @if(cvocInputControls[i].getError('invalid'))
        {
        <mat-error>{{'No Results found for Level '+(i+1) }}</mat-error>
        }
    </mat-form-field>
</div>