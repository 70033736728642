import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_susi_v2',
  },
  authorities: {
    signUpSignIn: {
      authority:
        environment.SIGNUP_SIGNIN_AUTHORITY,
    }

  },
  authorityDomain: environment.AUTHORITY_DOMAIN,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel, message, containsPii) => {
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
    allowRedirectInIframe: true
  },
};

// Below code for future configurations
export const protectedResources = {
  todoListApi: {
    endpoint: '',
    scopes: [''],
  },
};

export const protectedMulesoftResources = {
  scopes: [environment.MULESOFT_SCOPE],
};
export const loginRequest = {
  scopes: [''],
};

export const protectedDotnetResources = {
  scopes: [environment.DOTNET_SCOPE_READ, environment.DOTNET_SCOPE_WRITE]
};

export const silentRequest = {
  scopes: [],
  loginHint: '',
};
