export const environment = 
{
    production: false,
    API_URL_MULESOFT: 'https://api-cloudhubcentral-st.ecolab.com/one-customer/v1/users/customers',
    API_DOTNET_TVD_URL:'https://stg-onecustomer-api.ecolab.com/tvd/',
    API_DOTNET_TVD_Language_URL:'https://stg-onecustomer-api.ecolab.com/globalpreferences/userpreferences',
    AUTHORITY_DOMAIN:'wintersnowb2c.b2clogin.com',
    CLIENT_ID: '5f0a375f-9d77-4ec4-b70b-2e777e8fcdd1',
    SIGNUP_SIGNIN_AUTHORITY:'https://wintersnowb2c.b2clogin.com/stg-account.ecolab.com/B2C_1A_Connect_OIDC_SIGNIN',
    ELASTIC_APM_SERVER_URL:'https://7f18513b91d543dfa791112eabe1324a.apm.eastus2.azure.elastic-cloud.com:443',
    MULESOFT_SCOPE:'https://stg-account.ecolab.com/6de9da30-511c-4fc4-9b3f-467d55f9a73b/scope:read.only',
    DOTNET_SCOPE_READ:'https://stg-account.ecolab.com/1C_Api_stg/1C.Read',
    DOTNET_SCOPE_WRITE:'https://stg-account.ecolab.com/1C_Api_stg/1C.Write',
    CDM_AUTHORIZATION_URL:'https://api-cloudhubcentral-st.ecolab.com/one-customer/v1/users/applications/TVD',
    ECOLAB_WEB: 'https://dotcom-stage-cds.ecolab.com/',
    DI_APP_LINK: 'https://stg-myhomepage.ecolab.com/',
    API_DOTNET_TVD_Logout_URL:'https://stg-onecustomer-api.ecolab.com/globalpreferences/user/logout'
};