import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ADD_VALUE_ACTIVITY_METRIC, CATEOGRYID_MANUAL, CLOSE, DIALOG_MODE_ADD, DIALOG_MODE_MODIFY, DELETE_ICON, PLUSIMG, VALUE_ACTIVITY_METRIC, VALUE_ACTIVITY_METRICS } from '../constants/app.constants';
import { Catogory, ValueActivityMetrics, Vamcategory } from '../../features/tvd-setup/interfaces/metrics.interface';
import { CustomerService } from '../../features/tvd-setup/services/customer.service';
import { catchError, finalize, forkJoin, of } from 'rxjs';
import { TranslationService } from '../../features/tvd-setup/services/translation.service';

@Component({
  selector: 'app-value-metric-dailog',
  templateUrl: './value-metric-dailog.component.html',
  styleUrl: './value-metric-dailog.component.scss'
})
export class ValueMetricDailogComponent {
  metricsForm: FormGroup;
  deletedMetricIds: number[] = [];
  initialFormData: any;
  PLUSIMG: string = PLUSIMG;
  DELETE_ICON: string = DELETE_ICON;
  VALUE_ACTIVITY_METRICS: String = VALUE_ACTIVITY_METRICS;
  VALUE_ACTIVITY_METRIC: String = VALUE_ACTIVITY_METRIC;
  ADD_VALUE_ACTIVITY_METRIC: String = ADD_VALUE_ACTIVITY_METRIC;
  CLOSE_ICON: string = CLOSE;
  typeCatogory: Vamcategory[];
  inputDisabled: boolean[] = [];
  dailogModel: any
  updatedMetrics = [];
  dialogCloseResponse: any = null;
  isApply: boolean = false;
  constructor(private fb: FormBuilder, public dialogRef: DialogRef<ValueMetricDailogComponent>, private customerService: CustomerService, public trnaslateServe: TranslationService) {

  }

  ngOnInit() {
    this.dailogModel = this.dialogRef?.config?.data;
    this.catogoryType();
    this.metricsForm = this.fb.group({
      metrics: this.fb.array([])
    });
    this.initialFormData = { metrics: [] };
    if ((this.dailogModel.mode).toUpperCase() === DIALOG_MODE_ADD.toUpperCase()) {
      this.addMetric()
    }
    if ((this.dailogModel.mode).toUpperCase() === DIALOG_MODE_MODIFY.toUpperCase()) {
      this.setMetrics(this.dailogModel?.getData)
    }
  }

  catogoryType() {
    this.customerService?.getCatogoryList()?.subscribe((vamcategory: Catogory) => {
      this.typeCatogory = vamcategory.data.vamcategories;
    });
  }

  onSelectedChange(event: any, index: number) {
    const selectedCatogoryID = event;
    const selectedCatogory = this.typeCatogory.find(cat => cat.categoryId === selectedCatogoryID);
    if (selectedCatogoryID) {
      this.metrics.at(index).get('metricName')?.setValue(selectedCatogory.vamcategory);
      this.metrics.at(index).get('metricName')?.disable(); // Disable the input field
      this.inputDisabled[index] = true;
    }
    if (selectedCatogoryID === CATEOGRYID_MANUAL) {
      this.metrics.at(index).get('metricName').setValue('');
      this.metrics.at(index).get('metricName').enable(); // Disable the input field
      this.inputDisabled[index] = false;

    }
  }

  get metrics() {
    return this.metricsForm.get('metrics') as FormArray;
  }

  createMetric(metric: ValueActivityMetrics): FormGroup {
    return this.fb.group({
      id: [metric.id],
      categoryId: [metric.categoryId, Validators.required],
      metricName: [{ value: metric.metricName, disabled: metric.categoryId !== CATEOGRYID_MANUAL }],
      metricValue: [metric.metricValue, [Validators.required, Validators.min(0)]], 
      isCustom: [metric.isCustom]
    });
  }

  setMetrics(metrics: ValueActivityMetrics[]) {

    const metrcFormArray =
      this.metricsForm.get('metrics') as FormArray;
    metrics?.forEach(metric => {
      if (metric.id === 0) {
        metric.categoryId = CATEOGRYID_MANUAL;
        this.inputDisabled.push(false)
      }
      this.inputDisabled.push(true)
      metrcFormArray.push(this.createMetric(metric))
    })
  }

  enableManualInput(index: number): void {
    this.metrics.at(index).get('metricName')?.enable();
    this.inputDisabled[index] = false;
  }

  addMetric() {
    if (this.metrics.length < 8) {
      const metricForm = this.fb.group({
        id: [0],
        categoryId: [CATEOGRYID_MANUAL, Validators.required],
        metricName: ['', Validators.required],
        metricValue: ['', [Validators.required, Validators.min(0)]],
        isCustom: [true]
      });
      this.metrics.push(metricForm);
      this.inputDisabled.push(false);
    }
  }

  clearField(index: number, fieldName: string) {
    const control = this.metrics.at(index).get(fieldName);
    if (control) {
      control.setValue('');
    }
  }

  deleteMetric(index: number, id: number) {
    if (id !== 0) this.deletedMetricIds.push(id);
    this.metrics.removeAt(index);
  }

  isControlModified(control: AbstractControl, initialValue: any): boolean {
    return control.value !== initialValue;
  }

  applyMetrics() {
    this.isApply = true;
    const apiCalls = [];
    if (this.metricsForm.invalid) {
      return;
    }
    this.metrics.controls.forEach(control => {
      control.get('metricName')?.enable({ emitEvent: false });
    });
    this.updatedMetrics = this.metrics.controls.map(control => {
      control.value.isCustom = control.value.categoryId === CATEOGRYID_MANUAL ? true : false;
      return control.value;
    });
    const formData = {
      userId: this.dailogModel.additionalData[1],
      customerId: this.dailogModel.additionalData[0].customerID,
      valueActivityMetrics: this.updatedMetrics,
    };

    if (this.deletedMetricIds.length > 0) {
      let data =
      {
        customerId: this.dailogModel.additionalData[0].customerID, vamIds: this.deletedMetricIds
      }
      apiCalls.push(
        this.customerService.deleteMetrics(data).pipe(
          catchError(error => {
            console.error('Delete metrics error:', error);
            return of(null); // Return null on error to ensure forkJoin completes
          })
        )
      );
    }
    if (this.updatedMetrics.length > 0) {
      apiCalls.push(
        this.customerService.createMetrics(formData).pipe(
          catchError(error => {
            console.error('Create metrics error:', error);
            return of(null); // Return null on error to ensure forkJoin completes
          })
        )
      );
    }
    if (apiCalls.length > 0) {
      forkJoin(apiCalls).pipe(
        finalize(() => {
          this.dialogRef.close(this.dialogCloseResponse);
        })
      ).subscribe(responses => {
        responses.forEach(response => {
          if (response) {
            this.dialogCloseResponse = response;
          }
        });
      });
    } else {
      this.dialogRef.close(this.dialogCloseResponse);
    }
  }

  closeModel() {
    this.dialogRef.close(this.dialogCloseResponse);
    this.updatedMetrics = [];
  }
  //handle input with validation 
  handleInput(event: Event , index:number): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
 
     // Sanitize the input value
    const sanitizedValue = inputValue
    .replace(/[^0-9.]/g, '') // Remove non-numeric and non-dot characters
    .replace(/(\..*)\./g, '$1') // Allow only one dot
    .replace(/(\.\d{2}).+/g, '$1') // Allow up to two decimal places
    .replace(/^\.+/, ''); // Remove leading dots

    // Update the input value
    inputElement.value = sanitizedValue;

    //update the form control value 
    this.metrics.at(index).get('metricValue')?.setValue(sanitizedValue)
  }
}
