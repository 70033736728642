<div class="timeoutContainer">
<div class="title-wrap">
<img src={{alertImg}} style="margin-top: 10px;"/>
<h2 mat-dialog-title>{{timeout_header}}</h2>
</div>
<mat-dialog-content innerHTML={{timeout_content}}></mat-dialog-content>
<mat-dialog-actions>
  <a mat-button mat-dialog-close (click)="logOut()">Go To Login</a>
  <a mat-button mat-dialog-close (click)="reloadPage()">Reload</a>

</mat-dialog-actions>
</div>