import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { IL1DetailsResponse, IL2DetailsResponse, ILDetailsRequest } from '../interfaces/IDetailsL1';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CustomerApi } from '../interfaces/customer.interface';
import { Catogory, MetricData, Metrics } from '../interfaces/metrics.interface';
import { MsalService } from '@azure/msal-angular';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  private readonly restApiURL: string;
  public errorOccured:BehaviorSubject<boolean> =new BehaviorSubject(false);

  constructor(private http: HttpClient, private authService: MsalService) {
  }

  //getCustomer function is used to get customer details
  getCustomerList(): Observable<CustomerApi> {
    // debugger
    return this.http.get<CustomerApi>(environment.API_URL_MULESOFT).pipe(catchError(this.errorHandler));
  }

  getMetricsList(userid: string, customerid: string): Observable<Metrics> {
    return this.http.get<Metrics>(`${environment.API_DOTNET_TVD_URL}valueactivitymetrics?userid=${userid}&customerid=${customerid}`).pipe(catchError(this.errorHandler));
  }

  createMetrics(MetricData: MetricData): Observable<Metrics> {
    return this.http.post<Metrics>(`${environment.API_DOTNET_TVD_URL}valueactivitymetrics`, MetricData).pipe(catchError(this.errorHandler));
  }

  deleteMetrics(MetricDeleteData: any): Observable<any> {
    return this.http.delete<any>(`${environment.API_DOTNET_TVD_URL}valueactivitymetrics`, { body: MetricDeleteData }).pipe(catchError(this.errorHandler));
  }

  getCatogoryList(): Observable<Catogory> {
    return this.http.get<Catogory>(`${environment.API_DOTNET_TVD_URL}valueactivitymetrics/categories`).pipe(catchError(this.errorHandler));
  }
 getDetails<T>(endPoint: string, customerID: string, detailRequest: ILDetailsRequest): Observable<T> {
    return this.http.post<T>(`${environment.API_URL_MULESOFT}/${customerID}/${endPoint}`, detailRequest).pipe(
      catchError(this.errorHandler));;
  }

  getL1TileDetails(customerID: string, startDate?: string, endDate?: string, filterObj?:any,unitOfMeasure:string='Imperial',currencyCode:string='USD'): Observable<IL1DetailsResponse> {
    let customerDetail: ILDetailsRequest;
    if(filterObj)
    {
      customerDetail = filterObj;
    }
    else
    {
      customerDetail= { startDate: startDate, endDate: endDate, unitOfMeasure: unitOfMeasure, currencyCode:currencyCode };
    }
    return this.getDetails<IL1DetailsResponse>('level1', customerID, customerDetail)
  }

  getL2TileDetails(customerID: string, startDate?: string, endDate?: string,filterObj?:any,unitOfMeasure:string='Imperial',currencyCode:string='USD'): Observable<IL2DetailsResponse> {
    let customerDetail: ILDetailsRequest;
    if(!filterObj)
    {
      customerDetail= { startDate: startDate, endDate: endDate, unitOfMeasure: unitOfMeasure, currencyCode:currencyCode };
    }
    else
    {
      customerDetail = filterObj;
    }
    return this.getDetails<IL2DetailsResponse>('level2', customerID, customerDetail)
  }

  errorHandler = (error: HttpErrorResponse) => {
    this.errorOccured.next(true);
    console.error(error.message);
    return throwError(error.message || "Server Error");
}

  getUserMailID() {
    return this.authService.instance.getAllAccounts()[0].localAccountId
  }


  getPreferences(emailId: string): Observable<any> {
    const url = environment.API_DOTNET_TVD_Language_URL;
    return this.http.get<any>(url, {
      params: {
        EmailId: emailId
      }
    });
  }
}