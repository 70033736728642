import { Component, Input } from '@angular/core';
import { ENERGY, VIEW_LESS, VIEW_LESS_ICON_BLUE, VIEW_LESS_ICON_WHITE, VIEW_MORE, VIEW_MORE_ICON_BLUE, VIEW_MORE_ICON_WHITE, WASTE, WATER, DEFINITION ,ICON_CLOSE_BLACK,ICON_CLOSE_WHITE } from '../../constants/app.constants';
import { ITileData } from '../../../features/tvd-setup/interfaces/ITileData';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../../features/tvd-setup/services/translation.service';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrl: './cards.component.scss'
})
export class CardsComponent {

  @Input() bgColor;
  @Input() customerTilesData: ITileData;
  @Input() customer_CnB_TilesData: ITileData;
  viewLabelText = VIEW_MORE;
  expandTile: boolean = false;
  viewIcon_blue = VIEW_MORE_ICON_BLUE;
  viewIcon_white = VIEW_MORE_ICON_WHITE;
  definition = DEFINITION
  viewLess: string = '';
  viewMore: string = '';
  isOverlayVisible = false;
  jsonData: any;
  helpText: string[];
  icon_close_black=ICON_CLOSE_BLACK;
  icon_close_white=ICON_CLOSE_WHITE;


  constructor(private translate: TranslateService, private translateService: TranslationService) { }

  ngOnInit() {
    this.getTileId();
    this.fetchHelpText();
    this.translate.stream('TVD').subscribe((values) => {
      this.viewLabelText = values.VIEWMORE;
      this.viewLess = values.VIEWLESS;
      this.viewMore = values.VIEWMORE;
      this.definition = DEFINITION;
    })
  }

  viewMoreClicked() {
    if (this.expandTile) {
      this.expandTile = false;
      this.viewLabelText = this.viewMore;
      this.viewIcon_blue = VIEW_MORE_ICON_BLUE;
      this.viewIcon_white = VIEW_MORE_ICON_WHITE;
    }
    else {
      this.expandTile = true;
      this.viewLabelText = this.viewLess;
      this.viewIcon_blue = VIEW_LESS_ICON_BLUE;
      this.viewIcon_white = VIEW_LESS_ICON_WHITE;
    }
  }

  getTileId(): string {
    const data = this.customer_CnB_TilesData || this.customerTilesData;
    // Check if data is a valid object and contains the tileName property.
    if (data && typeof data === 'object' && 'tileName' in data) {
      return data.tileName.toLowerCase();
    }
    return '';
  }
  onToggleOverlay() {
    this.isOverlayVisible = !this.isOverlayVisible;
  }
  onOverlayClose() {
    this.isOverlayVisible = false;
  }

  /**
* Fetches and assigns help text based on the tile ID.
* 
* - Subscribes to translation stream to get jsonData.
* - Ensures jsonData is an array.
* - Searches for help text in Level 1, if not found, searches in Level 2.
* - Assigns found help text to this.helpText.
*/
  fetchHelpText(): void {
    this.translate.stream('data').subscribe((values) => {
      this.jsonData = values;

      if (Array.isArray(this.jsonData)) {
        const tileId = this.getTileId().toLowerCase().trim();

        // Search in Level 1
        const level1 = this.jsonData.find((level: any) => level.level === '1');
        this.helpText = this.getHelpTextForLevel(level1, tileId);

        // If not found, search in Level 2
        if (!this.helpText) {
          const level2 = this.jsonData.find((level: any) => level.level === '2');
          this.helpText = this.getHelpTextForLevel(level2, tileId);
        }
      }
    });
  }

  /**
   * Searches for HelpText in the specified level.
   * 
   * @param {any} level - The level to search in.
   * @param {string} tileId - Formatted tile ID.
   * @returns {string[] | undefined} - Found HelpText or undefined.
   */
  getHelpTextForLevel(level: any, tileId: string): string[] | undefined {
    return level?.data?.find((cat: any) => cat.category.toLowerCase().trim() === tileId)?.HelpText;
  }
  // Method to check if tileName is 'Water', 'Energy', or 'Waste'
  isWaterEnergyWaste(): boolean {
    const tileName = this.customerTilesData?.tileName;
    return [WATER, ENERGY, WASTE].includes(tileName);
  }
}
