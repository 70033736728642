import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild, computed, inject, input, model, signal } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { FilterService } from '../../services/filter.service';
import { ICustomerInfo } from '../../interfaces/customer.interface';
import { ISite, ISiteData } from '../../interfaces/ISite.interface';
import { SITEFILTER_SEARCH_ICON, SITE_IDENTIFIER, SITE_NOT_FOUND_MSG } from '../../../../shared/constants/app.constants';
import { CustomerNameErrorStateMatcher } from '../../classes/customerNameTextMatcher';

@Component({
  selector: 'app-site-filter',
  templateUrl: './site-filter.component.html',
  styleUrl: './site-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteFilterComponent {

  @Output() enableApply:EventEmitter<any>=new EventEmitter();
  @Input() siteFilter
  siteKeyword=SITE_IDENTIFIER;
  matcher = new CustomerNameErrorStateMatcher();
  customerInfo: ICustomerInfo;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  siteCtrl = new FormControl('');
  filteredFruits: Observable<string[]>;
  addedSites: ISite[] = [];
  siteList: ISiteData;
  siteFilteredList: Observable<ISite[]>
  searchIcon = SITEFILTER_SEARCH_ICON;
  SiteErrorText=SITE_NOT_FOUND_MSG;
  @ViewChild('siteInput') siteInput: ElementRef<HTMLInputElement>;


  ngOnInit() {
    this._filterService.currentToggle.next(this.siteKeyword);
    this._filterService.selectedEVOC.next(null);//saved EVOC made null
    this._filterService.selectedCVOC.next(null);//saved EVOC made null
    this.fetchSitesForCustomer();
    const savedSites= this._filterService.appliedSites.value; 
      if(savedSites && savedSites?.length>0)
      {
        this.addedSites=[...savedSites]
        this.enableApply.emit({apply:false,type:this.siteKeyword,data:true});
      }
      else
      {
        this.enableApply.emit({apply:false,type:this.siteKeyword,data:false});
      }
  }
  constructor(private _filterService: FilterService) { }

  fetchSitesForCustomer() {
        this.siteList = this.siteFilter.response;
        this.siteFilteredList = this.siteCtrl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterSite(value || '')),
        );
      }
 
  removeSite(site: ISite): void {
    const index = this.addedSites.indexOf(site);
    if (index >= 0) {
      this.addedSites.splice(index, 1);
      if(index==0)
      {
        this.enableApply.emit({apply:false,type:this.siteKeyword,data:false});
      }
      else
      {
        this.enableApply.emit({apply:false,type:this.siteKeyword,data:true});
      }

    }
    else
    {
      this.enableApply.emit({apply:false,type:this.siteKeyword,data:false});
    }
    this._filterService.addedSites.next(this.addedSites);
  }

  selectedSite(siteIdEvent: MatAutocompleteSelectedEvent, siteNameEvent: MatAutocompleteSelectedEvent): void {
    const siteId = siteIdEvent.option.value;
    const siteName = siteNameEvent.option.viewValue;
    if (!this.addedSites.some(site => site.siteId.toLowerCase() === siteId.toLowerCase())) {
      this.addedSites.push({ siteId, siteName });
      this._filterService.addedSites.next(this.addedSites);
      this.enableApply.emit({apply:false,type:this.siteKeyword,data:true});
    }
    else
    {
      this.enableApply.emit({apply:false,type:this.siteKeyword});
    }
    this.siteCtrl.setValue(null);
  }

  private _filterSite(value: string): ISite[] {
    const filterValue = value.toLowerCase();
    let filteredSites = this.siteList?.sites.filter(s => s.siteName?.toLowerCase().includes(filterValue));
    if(filteredSites?.length==0)
    {
      this.siteCtrl.setErrors({'invalidSite': true });
      this.enableApply.emit({apply:true,type:this.siteKeyword});
    }
    else
    {
      this.siteCtrl.setErrors(null);
      this.enableApply.emit({apply:false,type:this.siteKeyword});
    }
    return filteredSites;
  }

  ngOnDestroy()
  {
    this._filterService.addedSites.next(this.addedSites);
  }
}
