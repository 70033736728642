<div class="customer-main-details">
  <div class="customer-main-details-wrapper">
    <div class="customer-main-details-left">
      <div>
        <div class="customer-main-details-tvd">{{ 'TVD.TOTALVALUE' | translate }}</div>
        <div class="customer-main-details-tvd-wrapper">
          <div class="customer-main-details-Million">
            <span>
              <span class="customer-main-details-data">{{totalValue | numberSuffix:transformationType.withCurrency}}</span>
            </span>
          </div>
          <div>
            <div class="customer-main-details-usd">USD</div>
          </div>
        </div>
        <div class="customer-align">
          <div class="customer-visibility">
            <div class="ellipse ellipse-tvd"></div>
            <div class="customer-data-label tvd-label-align">{{'TVD.TOTALVALUEDELIVERED' | translate}}</div>
          </div>
          <div class="customer-visibility">
            <div class="ellipse ellipse-opportunity"></div>
            <div class="customer-data-label pipe-label-align">{{'TVD.TOTALVALUEPIPELINE' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="customer-main-details-right">
      <div class="customer-value customer-value-align">
        <span [ngClass]="totalValueDeliveredInMillion ||totalValueDeliveredInMillion==0 ? 'customer-value-wrapper':'customer-value-no-wrap'">
          <span>{{totalValueDeliveredInMillion | numberSuffix:transformationType.withCurrency}}</span>
          <span class="customer-value-usd">USD</span>
        </span>
        <div id="tvd"></div>
        <div class="customer-value-label">{{'TVD.VALUEDELIVERED' | translate}}</div>
      </div>
      <div class="customer-value">
        <div>
          <div [ngClass]="totalOpportunityInMillion ||totalOpportunityInMillion==0 ? 'customer-value-wrapper':'customer-value-no-wrap'">
            <span>{{totalOpportunityInMillion | numberSuffix:transformationType.withCurrency}}</span>
            <span class="customer-value-usd">USD</span>
          </div>
          <div id="enterpriseSitePipleine"></div>
          <div class="customer-value-label">{{'TVD.VALUEPIPELINE' | translate}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
