import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MODIFY, MODIFYIMG, PLUSIMG, VALUE_ACTIVITY_METRICS, DIALOG_MODE_ADD, DIALOG_MODE_MODIFY, APPLYVALUECHANEGE } from '../../../../shared/constants/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { ValueMetricDailogComponent } from '../../../../shared/value-metric-dailog/value-metric-dailog.component';
import { Metrics, ValueActivityMetrics } from '../../interfaces/metrics.interface';
import { CustomerService } from '../../services/customer.service';
import { ICustomerInfo } from '../../interfaces/customer.interface';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-value-activity-metric',
  templateUrl: './value-activity-metric.component.html',
  styleUrl: './value-activity-metric.component.scss',

})
export class ValueActivityMetricComponent implements OnInit {
  @Input() customer: ICustomerInfo;
  @Input() applyClickChanges: ICustomerInfo
  boxdata: ValueActivityMetrics[];
  isNew: number | null = 0;
  MODIFYIMG: string = MODIFYIMG;
  PLUSIMG: string = PLUSIMG;
  VALUE_ACTIVITY_METRICS: string = '';
  MODIFY: string = '';
  subscription: Subscription;
  DIALOG_MODE_ADD = DIALOG_MODE_ADD;
  DIALOG_MODE_MODIFY = DIALOG_MODE_MODIFY;
  APPLYVALUECHANEGE = APPLYVALUECHANEGE;
  currentUserEmail: string;
  constructor(public dialog: MatDialog, private customerService: CustomerService, private translateService:TranslateService) {
  }
  ngOnInit(): void {
    if (this.customer) {
      this.currentUserEmail = this.customerService.getUserMailID();
      this.getCustomer()
    }

  }
  ngOnChanges(applyClickChanges: SimpleChanges): void {
    let changes = applyClickChanges[APPLYVALUECHANEGE]
    if (changes && changes.previousValue !== changes.currentValue) {
      changes.currentValue = this.customer;
      this.getCustomer()
    }
    this.translateService.stream('TVD').subscribe((values)=>{
        this.MODIFY = values.MODIFY;
        this.VALUE_ACTIVITY_METRICS = values.VALUE_ACTIVITY_METRICS;
    })
  }

  getCustomer() {
    this.customerService?.getMetricsList(this.currentUserEmail, this.customer.customerID)?.subscribe((metrics: Metrics) => {
      if (metrics.data) {
        this.boxdata = metrics.data.valueActivityMetrics
        this.isNew = this.boxdata.length;
      }
      if (metrics.data === null) {
        this.boxdata = [];
        this.isNew = 0;
      }
    });
  }
  valueActiveMetric(mode: typeof DIALOG_MODE_ADD | typeof DIALOG_MODE_MODIFY) {
    const dialogRef = this.dialog.open(ValueMetricDailogComponent, {
      width: '651px',
      height: 'auto',
      data: {
        mode: mode,
        additionalData: [this.customer, this.currentUserEmail],
        getData: this.boxdata
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getCustomer();
    });
  }
}
