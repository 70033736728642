import { Component, Input} from '@angular/core';
import { TranslationService } from '../../../features/tvd-setup/services/translation.service';
import { TRANSFORMATION_TYPE } from '../../constants/app.constants';

@Component({
  selector: 'app-total-value',
  templateUrl: './total-value.component.html',
  styleUrl: './total-value.component.scss',
})
export class TotalValueComponent {

  constructor(public translateService: TranslationService) {}

  @Input() totalValue: number;
  @Input() totalValueDeliveredInMillion: number;
  @Input() totalOpportunityInMillion: number;
  transformationType = TRANSFORMATION_TYPE

}
