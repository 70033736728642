import { Component } from '@angular/core';

@Component({
  selector: 'app-test-input',
  // template: '<app-header [name]="testData"></app-header>',
  template: `<app-total-value [totalValue]="testtotalValue" [totalValueDeliveredInMillion]="testtotalValueDeliveredInMillion" [totalOpportunityInMillion]="totalOpportunityInMillion"></app-total-value>`,
})
export class TestInputComponent {
  testtotalValue = 5;
  testtotalValueDeliveredInMillion = 10;
  totalOpportunityInMillion = 20;
}
