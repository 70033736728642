<div class="heading">
    <div class="heading-wrapper">
        <div class="ecolab-icon">
            <a href="https://www.ecolab.com">
                <img [src]="ecolab" alt="ecolab"/>
            </a>
        </div>
        <div class="heading-user">
            <span class="heading-user-photo">
                <img [src]="user_icon" alt="user_icon"/>
            </span>
            <span class="user-name" id="first-last-name">
                {{userName}}
                <br />
            </span>
            <span *ngIf="show" class="user-drop-down" id="drop-down">
                <span (click)="openPreferenceMenu($event)"
                    [ngClass]="displayPrefernce === true ? 'bg-color-up' : 'bg-color-down'">
                    <i [ngClass]="displayPrefernce === true ? 'arrow-up' : 'arrow-down'" [id]="displayPrefernce"></i>
                </span>
            </span>
        </div>
    </div>
    <div class="user-select">
        <app-preferences [userPreferenceClicked]="preference"></app-preferences>
    </div>