<mat-divider></mat-divider>

<div class="operating-filter-container">
<span class="operating-filter-container-label">{{'TVD.OPTYPE' | translate}}</span>
@if(isBusy)
{
    <mat-spinner style="margin-left:20px" [diameter]="50"></mat-spinner>
}
<mat-chip-set>
  <mat-chip-option  *ngFor="let op of operatingTypeList"  [selected]="op?.toLowerCase() === selectedOperatingType?.toLowerCase()" (click)="selectOperatingType(op)">
    {{op}}
  </mat-chip-option>
</mat-chip-set>
</div>