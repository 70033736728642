<mat-divider></mat-divider>

<div class="division-container">
  <div class="division-container-header">
    <span class="division-container-header-label">{{'TVD.CUSTOMER_DIVISION' | translate}}</span>
    <div class="sideBtns">
      <span class="division-container-header-selectAll" (click)="setAll(true)">{{'TVD.SELECT_ALL' | translate}}</span>
      <span class="division-container-header-clearAll" (click)="setAll(false)">{{'TVD.CLEAR_ALL' | translate}}</span>
    </div>

  </div>
  @if(isBusy)
  {
  <mat-spinner style="margin-left:20px" [diameter]="50"></mat-spinner>
  }
  <section class="row">
    <section class="col">
      @for (t1 of divisionLstPart1; track t1)
      {
      <mat-checkbox [(ngModel)]="t1.completed" [color]="checkBoxColor" [checked]="t1.completed"
        (change)="selectDivisions(t1,$event.checked)">
        {{t1.divisionName.replace(KAY_QSR,QSR)}}
      </mat-checkbox>
      <!-- <span>
            <ul>
                @for (subtask of t1.subtasks; track subtask) {
                <li>
                    <mat-checkbox [(ngModel)]="subtask.completed">
                        {{subtask.divisionName}}
                    </mat-checkbox>
                </li>
                }
            </ul>
        </span> -->
      }
    </section>
    <div class="col">
      @for (t2 of divisionLstPart2; track t2)
      {
      <mat-checkbox [(ngModel)]="t2.completed" [color]="checkBoxColor" [checked]="t2.completed"
        (change)="selectDivisions(t2,$event.checked)"> {{t2.divisionName.replace(KAY_QSR,QSR)}}
      </mat-checkbox>
      <!-- <span>
            <ul>
                @for (subtask of t2.subtasks; track subtask) {
                <li>
                    <mat-checkbox [(ngModel)]="subtask.completed">
                        {{subtask.divisionName}}
                    </mat-checkbox>
                </li>
                }
            </ul>
        </span> -->
      }
    </div>
  </section>
</div>
