import { Component, EventEmitter, Output } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { ICustomerInfo } from '../../interfaces/customer.interface';
import { FILTER_TYPES } from '../../constant/tvd-constant';

@Component({
  selector: 'app-operating-type-filter',
  templateUrl: './operating-type-filter.component.html',
  styleUrl: './operating-type-filter.component.scss'
})
export class OperatingTypeFilterComponent {

  @Output() enableApply:EventEmitter<any>=new EventEmitter();

  customerInfo: ICustomerInfo;
  filterName = FILTER_TYPES.OPTYPE;
  selectedOperatingType: string;
  operatingTypeList: string[];
  isBusy: boolean = true;

  constructor(private _filterService: FilterService) { }

  ngOnInit() {
    this._filterService.customerInfo.subscribe((res) => {
      this.customerInfo = res;
      const selectedOpType = this._filterService.appliedOperatingType?.value;

      if(selectedOpType)
      { 
        this.selectedOperatingType = selectedOpType;
        this.enableApply.emit({apply:false,type:'opType',data:true})
      }
      this.getOperatingTypeList();
    });
  }

  getOperatingTypeList() {
    this._filterService
      .fetchOperatingTypeList(this.customerInfo.customerID)
      .subscribe((res) => {
        this.operatingTypeList = res.response.operatingType || [];
        this.isBusy = false;
      });
    
  }

  selectOperatingType(selectedOption: string): void {
    if(selectedOption?.toUpperCase()==this.selectedOperatingType?.toUpperCase())
    {    
      this.selectedOperatingType='';
      this._filterService.selectedOperatingType.next(null);
      this.enableApply.emit({apply:false,type:'opType',data:false})
    }
    else
    {
    this.selectedOperatingType = selectedOption;
    this.enableApply.emit({apply:false,type:'opType',data:true})
    this._filterService.selectedOperatingType.next(this.selectedOperatingType?.toUpperCase());
  }
  }

}
