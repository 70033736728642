<div *ngFor="let item2 of evocfilterIterations;let j=index">
    <span class="evoc-label">{{evocFilterNames[j]}}</span>
    <mat-form-field appearance="outline">
        <input type="text" placeholder="{{evocFilterNames[j]+' name'}}" aria-label="Number" matInput
            [formControl]="evocInputControls[j]" [matAutocomplete]="auto"
            (click)="getCurrentLevelEVOCOptions(j+1,false)" (keyup)="validateEvocFilter(j+1)"
            id="inputTextColor" [errorStateMatcher]="matcher">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFnEVOC"
            (optionSelected)="getCurrentLevelEVOCOptions(j+1,true)">
            @for (option of filteredOptionsEvoc | async; track option)
            {
            <mat-option [value]="option">{{option}}</mat-option>
            }
        </mat-autocomplete>
        @if(evocInputControls[j].getError('invalid'))
        {
        <mat-error>{{'No Results found for '+ evocFilterNames[j] }}</mat-error>
        }
    </mat-form-field>
</div>