import { Component, Input } from '@angular/core';
import {ECOLAB ,PRIVACY_POLICY,TERMS_CONDITIONS} from '../../../features/tvd-setup/constant/tvd-constant'
import { PRIVACY_URL,TERMS_CONDITIONS_URL } from '../../../shared/constants/end-point-constants'
import { TranslationService } from '../../../features/tvd-setup/services/translation.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  Ecolab = ECOLAB;
  PRIVACY_POLICY = PRIVACY_POLICY;
  TERMS_CONDITIONS = TERMS_CONDITIONS;

  constructor(public translate: TranslationService) {}

  privacyTerms(clickedText: string): void {
    if (clickedText === 'PRIVACY_POLICY') {
      window.open(`${environment.ECOLAB_WEB}privacy-policy`, '_blank');
    }
    if (clickedText === 'TERMS_CONDITIONS') {
      window.open(`${environment.ECOLAB_WEB}terms-of-use`, '_blank');

    }
  }
}