<form>
  <mat-form-field class="siteWrapper" appearance="outline">
    <div class="siteWrapper-input-search">
      <img alt = "search-Icon" src="{{searchIcon}}" />
      <input placeholder="Search Sites" matInput [formControl]="siteCtrl" [matChipInputFor]="chipGrid"
        [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [errorStateMatcher]="matcher"/>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedSite($event,$event)">
      @for (site of siteFilteredList| async; track site) {
      <mat-option [value]="site.siteId">{{site.siteName}}</mat-option>
      }
    </mat-autocomplete>
    <mat-chip-grid #chipGrid aria-label="sites">
      @for (site of addedSites; track site) {
      <mat-chip-row id="chipStyle" (removed)="removeSite(site)">
        {{site.siteName}}
        <button matChipRemove [attr.aria-label]="'remove ' + site">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      }
    </mat-chip-grid>
    @if(siteCtrl.getError('invalidSite'))
    {
    <mat-error>{{SiteErrorText}}</mat-error>
    }
  </mat-form-field>
</form>