import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import * as Highcharts from 'highcharts';
import { COST_AND_BENEFITS, ENERGY, TILE_IMAGE_MAPPING, TRANSFORMATION_TYPE, TVD, VIEW_MORE, WASTE, WATER ,HOW_WAS_THIS_CALCULATED_WHITE ,HOW_WAS_THIS_CALCULATED_BLUE ,HOW_WAS_THIS_CAL} from '../../constants/app.constants';
import { ITileData } from '../../../features/tvd-setup/interfaces/ITileData';
import { TranslationService } from '../../../features/tvd-setup/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-tiles-data',
  templateUrl: './tiles-data.component.html',
  styleUrl: './tiles-data.component.scss',
})
export class TilesDataComponent {
  constructor(private changeDetectorRef: ChangeDetectorRef, public translateService: TranslationService, public translate: TranslateService) {
    this.translate.stream('TVD').subscribe((values) => {
      this.totalValueDelivered = values.TOTALVALUEDELIVERED;
      this.totalValuePipeline = values.TOTALVALUEPIPELINE;
      this.calculatedText = values.HOW_WAS_THIS_CAL;
    })
  }

  @Output() toggleOverlay = new EventEmitter<void>();
  @Input() customerTilesData: ITileData;
  @Input() viewMoreData: boolean;
  tileImage: string;
  tilesHeading: string;
  viewMore = VIEW_MORE;
  tvd = TVD;
  costAndBenefit = COST_AND_BENEFITS;
  transformationType = TRANSFORMATION_TYPE;
  totalValueDelivered: string = '';
  totalValuePipeline: string = '';
  icon_blue = HOW_WAS_THIS_CALCULATED_BLUE;
  icon_white = HOW_WAS_THIS_CALCULATED_WHITE;
  calculatedText = HOW_WAS_THIS_CAL;

  ngAfterViewInit() {
    if (this.customerTilesData) {
      this.getHighChartData(this.customerTilesData);
      this.getImageL2(this.customerTilesData?.tileName);
    }
    this.changeDetectorRef.detectChanges();
  }

  getHighChartData(customerData: ITileData): void {
    this.highCharts(
      Number(customerData?.totalValueDelivered?.replace(/\,/g, '')),
      Number(customerData?.opportunityAmount?.replace(/\,/g, ''))
    );
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });
  }

  getImageL2(tilesHeading: string): void {
    const tile = TILE_IMAGE_MAPPING.find(item => item.tileName === tilesHeading)
    this.tileImage = tile ? tile.imagePath : 'not_found';
  }

  highCharts(totalTVDAmount, totalOpportunityAmount): void {
    // @ts-ignore
    Highcharts.chart(this.customerTilesData?.tileName, {
      chart: {
        type: 'bar',
        margin: [0, 0, 0, 0],
        height: 50,
        width: 300,
        backgroundColor: 'transparent',
      },
      plotOptions: {
        bar: {
          borderRadius: '50%',
        },
        series: {
          groupPadding: 0.1,
          pointPadding: 0.3,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      title: {
        text: '',
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      tooltip: {
        enabled: true,
        headerFormat: '<table>',
        pointFormat:
          '<tr><td style="padding:0;font-size:1.5em;font-family: Roboto">' +
          '{series.userOptions.category}: </td>' +
          '<td style="padding:0; font-size: 1.5em;font-family: Roboto"><b>${point.y:,.2f}</b></td></tr>',
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          borderColor: '#006BD3',
          showInLegend: false,
          category: this.totalValueDelivered,
          data: [{ y: totalTVDAmount, color: '#006BD3' }],
        },
        {
          borderColor: '#4fc2b0',
          showInLegend: false,
          category: this.totalValuePipeline,
          data: [{ y: totalOpportunityAmount, color: '#4fc2b0' }],
        },
      ],
    });
  }
  ToggleHelpTextOverlay() {
    this.toggleOverlay.emit();
  }
  isWaterEnergyWaste(): boolean {
    const tileName = this.customerTilesData?.tileName;
    return [WATER, ENERGY, WASTE].includes(tileName);
  }
}
