import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerComponent } from './components/customer/customer.component';
import { MsalGuard } from '@azure/msal-angular';
import { UnauthorizedUserComponent } from '../../components/unauthorized-user/unauthorized-user.component';

const routes: Routes = [
  {
    path: '', component: CustomerComponent, pathMatch: 'full'
  },
  { 
    path: 'TotalValue',component: CustomerComponent
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class TvdSetupRoutingModule { }
