import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { ICVOCRequest, ICVOCResponse, ISavedCVOCHierarchy } from '../interfaces/ICVOCHierarchy';
import { environment } from '../../../../environments/environment';
import { IEVOCRequest, IEVOCResponse, SelectedEVOC } from '../interfaces/IEVOCHierarchy';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ISite, ISiteResponse } from '../interfaces/ISite.interface';
import { IOperatingTypeResponse } from '../interfaces/IOperatingType.interface';
import { ICustomerInfo } from '../interfaces/customer.interface';
import { ICustomerDivision, ICustomerDivisionResponse } from '../interfaces/ICustomerDivision.interface';
import { FILTER_TYPES } from '../constant/tvd-constant';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  public addedSites:BehaviorSubject<ISite[]>=new BehaviorSubject(null);
  public selectedCVOC:BehaviorSubject<ISavedCVOCHierarchy[]>=new BehaviorSubject(null);
  public selectedEVOC:BehaviorSubject<SelectedEVOC[]>=new BehaviorSubject(null);
  public selectedCustomerDivision:BehaviorSubject<ICustomerDivision>=new BehaviorSubject(null);
  public selectedOperatingType:BehaviorSubject<string>=new BehaviorSubject(null);
  //below are applied filters
  public appliedSites:BehaviorSubject<ISite[]>=new BehaviorSubject(null);
  public appliedCVOC:BehaviorSubject<ISavedCVOCHierarchy[]>=new BehaviorSubject(null);
  public appliedEVOC:BehaviorSubject<SelectedEVOC[]>=new BehaviorSubject(null);
  public appliedCustomerDivision:BehaviorSubject<ICustomerDivision>=new BehaviorSubject(null);
  public appliedOperatingType:BehaviorSubject<string>=new BehaviorSubject(null);
  public customerInfo:BehaviorSubject<ICustomerInfo>=new BehaviorSubject(null);
  public currentToggle:BehaviorSubject<string>=new BehaviorSubject(FILTER_TYPES.CVOC);

  public startDate:BehaviorSubject<string>=new BehaviorSubject(null);
  public endDate:BehaviorSubject<string>=new BehaviorSubject(null);

  public filterContainerName:BehaviorSubject<string>=new BehaviorSubject(null);
  constructor(private http: HttpClient) { }

  private getDetails<T>(endPoint: string, customerID: string): Observable<T> {
    return this.http.get<T>(`${environment.API_URL_MULESOFT}/${customerID}/${endPoint}`).pipe(
      catchError(this.errorHandler));
  }
  
  fetchOperatingTypeList(customerID:string)
  {
    return this.getDetails<IOperatingTypeResponse>('operating-type',customerID).pipe(
      catchError(this.errorHandler));
  }
  getNextCVOCFilter(cvocRequest:ICVOCRequest, customerID:string):Observable<ICVOCResponse>
  {
    cvocRequest.startDate=this.startDate.value;
    cvocRequest.endDate=this.endDate.value;
    return this.http.post<ICVOCResponse>(`${environment.API_URL_MULESOFT}/${customerID}/customer-hierarchy-levels`, cvocRequest).pipe(
    catchError(this.errorHandler));
  }

  getNextEVOCFilter(IEvocRequest: IEVOCRequest, customerID:string):Observable<IEVOCResponse>
  {
    return this.http.post<IEVOCResponse>(`${environment.API_URL_MULESOFT}/${customerID}/ecolab-hierarchy-levels`, IEvocRequest).pipe(
      catchError(this.errorHandler));
  }

  getSites(customerID:string):Observable<ISiteResponse>
  {
    return this.getDetails<ISiteResponse>('sites',customerID);
  }
  fetchCustomerDivisions(customerID:string):Observable<ICustomerDivisionResponse>
  {
    return this.getDetails<ICustomerDivisionResponse>('divisions',customerID);
  }

  errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error.message || "Server Error");
  }

  resetAllSavedFilterData() {
    this.selectedCVOC.next(null);
    this.selectedEVOC.next(null);
    this.addedSites.next(null);
    this.selectedCustomerDivision.next(null);
    this.selectedOperatingType.next(null);
  }
}
