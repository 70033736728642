import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DEFAULT_EVOC_SELECTION, EVOC_FILTER_NAMES } from '../../constant/tvd-constant';
import { FormControl } from '@angular/forms';
import { IEVOC, IEVOCRequest, SelectedEVOC } from '../../interfaces/IEVOCHierarchy';
import { Observable, map, startWith } from 'rxjs';
import { ICustomerInfo } from '../../interfaces/customer.interface';
import { FilterService } from '../../services/filter.service';
import { CustomerNameErrorStateMatcher } from '../../classes/customerNameTextMatcher';

@Component({
  selector: 'app-evoc-filter',
  templateUrl: './evoc-filter.component.html',
  styleUrl: './evoc-filter.component.scss'
})
export class EVOCFilterComponent {

  @Output() enableApply: EventEmitter<any> = new EventEmitter();
  matcher = new CustomerNameErrorStateMatcher();
  selectedFilterCount: number = 0;
  customerInfo:ICustomerInfo;
  evocfilterIterations: number[] = new Array(1);
  evocFilterNames: string[] = [...EVOC_FILTER_NAMES]
  evoc_L1_control = new FormControl('');
  evoc_L2_control = new FormControl('');
  evoc_L3_control = new FormControl('');
  evoc_L4_control = new FormControl('');
  evocInputControls: FormControl[] = [this.evoc_L1_control, this.evoc_L2_control, this.evoc_L3_control, this.evoc_L4_control];
  selectedEVOCFilter: SelectedEVOC[] = [...DEFAULT_EVOC_SELECTION];
  evocLevelResponse: IEVOC;
  filteredOptionsEvoc: Observable<string[]>;
  disableApply: boolean = true;


  ngOnInit() {
    let dataPresent:boolean;
    this._filterService.currentToggle.next('EVOC');
    this._filterService.selectedCVOC.next(null);//Making saved CVOC values as Null
    this._filterService.addedSites.next(null);//Making saved sites as Null
    this._filterService.customerInfo.subscribe((res)=>this.customerInfo=res);//making default call for 1st filter to reduce the lag time.
    const saved_evoc=this._filterService.appliedEVOC.value;
    saved_evoc?.length>0?(this.fetchSavedEVOCValues(saved_evoc),dataPresent=true): (this.fetchNextEVOCFilter(),dataPresent=false);
    this.enableApply.emit({apply:false,type:'evoc',data:dataPresent});
  }


  fetchSavedEVOCValues(evoc_data:SelectedEVOC[])
  {
    if (evoc_data) {
        this.selectedEVOCFilter = [...evoc_data];
        if(evoc_data?.length==0)
        {
          this.modifyEVOCFilterCount(evoc_data.length+1);
          return;
        }
        this.modifyEVOCFilterCount(evoc_data.length);
        evoc_data.forEach((data, i) => {
            this.evocInputControls[i].setValue(data.selectedValue);
        });
    }
   
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  constructor(private _filterService: FilterService, private cdref: ChangeDetectorRef) { }

  displayFnEVOC(customerName: string) {
    return customerName;
  }
  getCurrentLevelEVOCOptions(level: number, selection?: boolean) {
    var filterValue: string;
    if (!selection) {
      this.fetchNextEVOCFilter(level - 1, this.selectedEVOCFilter);
    }
    if (this.evocInputControls[level - 1].value && level < 5 && selection) {
      switch (level) {
        case 1:
          {
            filterValue = this.evocLevelResponse.region?.find(y => y.toLowerCase() == this.evocInputControls[level - 1].value.toLowerCase());
            this.evocDynamicFilter(filterValue, selection, level, 'region')
            break;
          }
        case 2:
          {
            filterValue = this.evocLevelResponse.market?.find(y => y.toLowerCase() == this.evocInputControls[level - 1].value.toLowerCase());
            this.evocDynamicFilter(filterValue, selection, level, 'market')
            break;
          }
        case 3:
          {
            filterValue = this.evocLevelResponse.allocationMarket?.find(y => y.toLowerCase() == this.evocInputControls[level - 1].value.toLowerCase());
            this.evocDynamicFilter(filterValue, selection, level, 'allocationMarket')
            break;
          }
        case 4:
          {
            filterValue = this.evocLevelResponse.country?.find(y => y.toLowerCase() == this.evocInputControls[level - 1].value.toLowerCase());
            this.evocDynamicFilter(filterValue, selection, level, 'country')
            break;
          }
      }
      this.enableApply.emit({apply:false,type:'evoc',data:true});
    }
    else {
      this.modifyEVOCFilterCount(level);
    }
    this.filteredOptionsEvoc=null;
    this.persistEVOCData();
  }

  //Below method dynamically increases/decreases the dropdowns
  evocDynamicFilter(a1: string, selection: boolean, level: number, hierarchyName: string) {
    if (a1 && level < 5 && selection) {
      this.selectedEVOCFilter[level - 1] = { level, hierarchyName, selectedValue: a1 };
      this.modifyEVOCFilterCount(level + 1);
      this.disableApply = false;
    } else {
      this.modifyEVOCFilterCount(level);
    }
  }

  validateEvocFilter(level: number) {
    var currentFilterList = this.GetEVOCLabelName(level);
    let dataPresent:boolean;
    var validFilter = currentFilterList?.find(data => data?.toLowerCase().includes(this.evocInputControls[level - 1]?.value.toLowerCase()));
    if (!this.evocInputControls[level - 1].value && level == 1) {
      this.disableApply = false;
      this.modifyEVOCFilterCount(level);
      dataPresent=false;
      this.selectedEVOCFilter=[];
      this.persistEVOCData();

    }
    else if (!this.evocInputControls[level - 1].value && level != 1) {
      this.disableApply = false;
      this.modifyEVOCFilterCount(level);
      this.evocInputControls[level - 1].setErrors(null);
      dataPresent=true;
      this.getCurrentLevelEVOCOptions(level-1,true);
      this.persistEVOCData();
    }
    else if(currentFilterList?.find(data => data?.toLowerCase()===this.evocInputControls[level - 1]?.value.toLowerCase()))
    {
      this.disableApply=false;
      this.evocInputControls[level - 1].setErrors(null)
      dataPresent=true;
    }
    else if (validFilter) {
      this.disableApply = true;
      this.evocInputControls[level - 1].setErrors(null)
      dataPresent=true;
    }
    else {
      this.disableApply = true;
      this.evocInputControls[level - 1].setErrors({ 'invalid': true })
      this.modifyEVOCFilterCount(level);
      dataPresent=true;
    }
    this.enableApply.emit({apply:this.disableApply,type:'evoc',data:dataPresent});

  }


  GetEVOCLabelName(level: number): string[] {
    var label: string[];
    switch (level) {
      case 1:
        {
          label = this.evocLevelResponse?.region;
          break;
        }
      case 2:
        {
          label = this.evocLevelResponse?.market;
          break;
        }
      case 3:
        {
          label = this.evocLevelResponse?.allocationMarket;
          break;
        }
      case 4:
        {
          label = this.evocLevelResponse?.country;
          break;
        }
    }
    return label;
  }

  modifyEVOCFilterCount(level: number) {
    if (level > 4)
      return;
    this.evocfilterIterations = new Array(level);
    this.evocInputControls.slice(level).forEach(control => {
      control.setValue(null);
    });
    this.selectedEVOCFilter.fill(null, level, 5);
  }

  fetchNextEVOCFilter(targetLevel: number = 0, selectedEVOCFilter?: SelectedEVOC[]) {

    const requestObj: IEVOCRequest = {
      customerName: this.customerInfo.customerName,
      startDate: this._filterService.startDate.value,
      endDate: this._filterService.endDate.value
    };

    for (let i = 0; i <= targetLevel; i++) {
      switch (i) {
        case 1:
          requestObj.region = selectedEVOCFilter[0].selectedValue;
          break;
        case 2:
          requestObj.market = selectedEVOCFilter[1].selectedValue;
          break;
        case 3:
          requestObj.allocationMarket = selectedEVOCFilter[2].selectedValue;
          break;
      }
    }

    this._filterService.getNextEVOCFilter(requestObj, this.customerInfo.customerID).subscribe(
      res => {
        this.evocLevelResponse = res.response;
        this.filteredOptionsEvoc = this.evocInputControls[targetLevel].valueChanges.pipe(
          startWith(''),
          map(value => this._filter_evoc(value || '', targetLevel + 1)),
        );
      }
    );
    // this.enableApply.emit({apply:this.disableApply,type:'evoc'});

  }


  _filter_evoc(value: string, level: number): string[] {
    const filterValue = value.toLowerCase();
    var res: string[];
    var curEvocFilter = this.GetEVOCLabelName(level);
    res = curEvocFilter.filter(val => val.toLowerCase().includes(filterValue));
    return res;
  }

  ngOnDestroy()
  {
    this.persistEVOCData();
  }
  persistEVOCData()
  {
    if(this.selectedEVOCFilter)
    {
      this.selectedEVOCFilter=this.selectedEVOCFilter.filter(x=>x?.selectedValue!=null)
      this._filterService.selectedEVOC.next(this.selectedEVOCFilter)
    }
  }

}
