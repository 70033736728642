<div class="all-filter">
  @if(isBusy)
  {
  <mat-spinner style="margin-left:20px;align-self:end;" [diameter]="50"></mat-spinner>
  }
  @else
  {
  <button (click)="openAllFilterView()" mat-raised-button [ariaDisabled]="isBusy" extended class="filter-btn"
    color="primary">
    <mat-icon>
      <img [src]="FILTER_ICON" />
    </mat-icon>
    <span class="filter">{{'TVD.ALLFILETERS' | translate}}</span> </button>
  }

  @if(displayAllFilter)
  {
    <mat-card>
      <mat-card-header>
        <div class="all-filter-label-top">
          <div class="all-filter-label-top-text">{{'TVD.FILTER_RESULTS' | translate}}</div>
          <div class="all-filter-label-top-close">
            <div class="all-filter-label-top-close-text" (click)="openAllFilterView()">{{'TVD.CLOSE' | translate}}</div>
            <div class="all-filter-label-top-close-icon" (click)="openAllFilterView()"></div>
          </div>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="all-filter-no-of-filters">
          {{selectedFilterCount}} {{'TVD.NUMBER_OF_FILTERS' | translate}}
        </div>
        <div class="all-filter-view-by-wrapper">
          <div class="all-filter-view-by-wrapper-title">
            {{'TVD.VIEW_BY' | translate}}
          </div>
        </div>
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="selectedFilterType">
          <mat-button-toggle [value]="filterType.CVOC" id="customerID" name="CUSTOMER_VIEW"
            (click)="changeToggle(filterType.CVOC)">{{'TVD.CUSTOMER_VIEW' | translate}}</mat-button-toggle>
          <mat-button-toggle [value]="filterType.EVOC" id="ecolabID" name="ECOLAB_VIEW"
            (click)="changeToggle(filterType.EVOC)">{{'TVD.ECOLAB_VIEW' | translate}}</mat-button-toggle>
          <mat-button-toggle [value]="filterType.SITES" id="sitesID" name="SITES_VIEW"
            (click)="changeToggle(filterType.SITES)">{{'TVD.SITES' | translate}}</mat-button-toggle>
        </mat-button-toggle-group><br />
        @if(selectedFilterType===filterType.CVOC)
        {
        <app-cvoc-filter (enableApply)="checkToEnableApply($event)"></app-cvoc-filter>
        }
        @if(selectedFilterType===filterType.EVOC)
        {
        <app-evoc-filter (enableApply)="checkToEnableApply($event)"></app-evoc-filter>
        }
        @if(selectedFilterType===filterType.SITES)
        {
        <app-site-filter [siteFilter]="siteFilter" (enableApply)="checkToEnableApply($event)"></app-site-filter>
        }
        <app-operating-type-filter (enableApply)="checkToEnableApply($event)"></app-operating-type-filter>
        <app-division-filter (enableApply)="checkToEnableApply($event)"></app-division-filter>
      </mat-card-content>
      <mat-card-actions>
        <button type="button" id="cancelBtnTheme" mat-raised-button color="accent"
          (click)="openAllFilterView()">{{'TVD.CANCEL' | translate}}</button>
        <button type="button" class="next-btn" mat-raised-button color="primary" (click)="calculateTotalFilters()"
          [disabled]="disableApply">{{'TVD.APPLY' | translate}}</button>
      </mat-card-actions>
    </mat-card>
  }
</div>
